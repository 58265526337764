#holding-page {
	background: $color-neutral;
	position: relative;
	height: 100%;

	.section-content {
		background: $color-white;
		box-shadow: 0 0 30px 0 rgba($color-black, 0.25);
		left: 50%;
		margin: 0 auto;
		max-width: 640px;
		padding: 60px 40px;
		position: absolute;
		right: 0;
		text-align: center;
		top: 100px;
		transform: translateX(-50%);
		width: 90%;

		@include mq(mobile) {
			top: 30px;
		}


		h1 {
			margin: 0;
			height: 0;
			position: absolute;
			text-indent: -9999px;
		}

		h2 {
			font-size: $font-size-larger;
			font-weight: $font-weight-light;
			margin: 0 0 40px;

			@include mq(mobile) {
				font-size: $font-size-med;
			}


			span {
				@include mq(mobile) {
					display: block;
				}
			}
		}

		.sub-logo {
			margin: 0 0 40px;
		}

		ul.list-info {
			@extend %list-default;

			font-size: 0;

			li {
				display: inline-block;
				font-size: $font-size-base;

				@include mq(mobile) {
					display: block;
				}


				a {
					font-size: $font-size-med;
				}

				.svg-icon {
					margin: 0 3px 0 0;
				}

				&:first-child {
					margin: 0 30px 0 0;

					@include mq(mobile) {
						margin: 0 0 20px;
					}
				}
			}
		}
	}
}
