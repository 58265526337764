#careers {
	.section-hero {
		@extend %common-hero;

		background-position: center center, center center;
		background-repeat: no-repeat, no-repeat;
		background-size: cover, 0px;

		@include mq(mobile) {
			background-size: 0px, cover;
			background-position: center, bottom;
		}
	}

	.section-main-copy {
		padding: 80px 0;

		@include mq(mobile) {
			padding: 45px 0;
		}


		.sub-content {
			@extend %container;

			display: flex;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				display: block;
			}

			@include mq(mobile) {
				flex-direction: column;
			}


			& > .sub-copy {
				padding-right: 125px;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					float: left;
					width: 65%;
				}

				@include mq(tablet-down) {
					padding-right: 50px;
				}


				@include mq(mobile) {
					padding-bottom: 60px;
					padding-right: 0;
					text-align: center;
				}


				h1, h2, h3, h4, h5 {
					font-size: $font-size-h4;
				}
			}

			.sub-staff-testimonials {
				background: $color-neutral-dark;
				border-radius: $common-border-radius;
				height: 100%;
				max-width: 475px;
				padding: 50px 40px;
				width: 100%;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					display: inline-block;
					float: left;
					width: 47%;
				}

				@include mq(tablet-down) {
					max-width: 360px;
				}


				@include mq(mobile) {
					max-width: none;
					padding: 40px;
				}


				h3 {
					color: $color-white;
					font-size: $font-size-larger;
					margin-bottom: 40px;

					@include mq(tablet-down) {
						margin-bottom: 20px;
					}


					@include mq(mobile) {
						font-size: $font-size-h4;
						font-weight: $font-weight-semibold;
					}
				}

				ul.list-testimonials {
					display: flex;
					flex-direction: column;
					height: calc(100% - 50px);
					margin: 0;

					li {
						margin: 0;

						.sub-copy {
							p {
								color: $color-white;
								font-size: $font-size-h4;
								font-weight: $font-weight-semibold;

								@include mq(tablet-down) {
									font-size: $font-size-large;
								}


								@include mq(mobile) {
									font-size: $font-size-med;
									font-weight: $font-weight-regular;
									line-height: $line-height-large;
								}
							}
						}
					}
				}

				ul.slick-dots {
					margin-top: 60px;

					@include mq(tablet-down) {
						margin-top: 40px;
					}


					& > li {
						outline: none;
						margin-right: 15px;

						button {
							height: 11px;
							outline: none;
							width: 11px;
						}
					}
				}
			}
		}
	}

	.section-benefits {
		clear: both;
		padding: 80px 0 120px;

		@include mq(tablet-down) {
			padding: 60px 0;
		}


		@include mq(mobile) {
			padding: 35px 0;
		}


		.sub-content {
			@extend %container;

			& > .sub-intro-copy {
				@extend %intro-copy-layout;

				margin-bottom: 70px;
			}

			ul.list-benefits {
				@extend %common-listings;

				@include mq(tablet) {
					flex-wrap: wrap;
					justify-content: center;
				}


				.sub-image {
					height: 150px;
					margin-bottom: 5px !important;

					&.mod-desktop {
						@include mq(tablet-down) {
							display: none;
						}
					}

					&.mod-mobile {
						display: none;

						@include mq(tablet-down) {
							display: block;
						}
					}
				}

				& > li {
					@include mq(tablet) {
						flex: 0 0 calc(33.333% - 24px);
						margin-bottom: 65px;

						&:nth-child(3), &:last-child {
							margin-right: 0;
						}
					}
				}
			}
		}
	}

	.section-accreditations {
		@extend %accreditations-block;

		margin-bottom: 105px;

		@include mq(mobile) {
			margin-bottom: 95px;
		}
	}

	.section-jobs {
		.sub-content {
			@extend %container;

			& > .sub-intro-copy {
				@extend %intro-copy-layout;

				margin-bottom: 70px;
			}

			ul.list-jobs {
				@extend %list-default;

				& > li {
					align-items: center;
					background: rgba($color-neutral-dark, 0.02);
					display: flex;
					margin-bottom: 3px;
					min-height: 90px;
					padding: 0 25px;
					position: relative;

					@include mq(mobile) {
						background: rgba($color-neutral-dark, 0.03);
						flex-direction: column;
						padding: 25px;
					}


					&:hover {
						.sub-icon {
							span {
								opacity: 1;
							}

							svg {
								animation: arrow-anim-right .5s;
								opacity: 1;
							}
						}
					}

					.sub-link {
						height: 100%;
						left: 0;
						position: absolute;
						top: 0;
						width: 100%;
						z-index: 999;
					}

					.sub-title {
						font-size: $font-size-h4;
						font-weight: $font-weight-bold;
						margin-right: 120px;

						@include mq(tablet) {
							font-size: $font-size-large;
						}


						@include mq(mobile) {
							color: $color-text;
							line-height: $line-height-med;
							margin-bottom: 30px;
							margin-right: 30px;
						}
					}

					.sub-deadline {
						color: $color-text-light;

						@include mq(mobile) {
							color: $color-text;
							width: 100%;

							strong {
								display: block;
							}
						}
					}

					.sub-icon {
						align-items: center;
						display: flex;
						overflow: hidden;
						margin-left: auto;
						max-width: 185px;
						width: 100%;

						@include mq(tablet) {
							display: none;
						}


						@include mq(mobile) {
							align-items: center;
							display: flex;
							margin-left: 0;
							margin-top: 30px;
							max-width: 100%;
						}


						span {
							opacity: 0;
							color: $color-secondary;
							font-weight: $font-weight-bold;
							display: inline-block;
							margin-right: 10px;
							transition: opacity .3s ease;

							@include mq(mobile) {
								font-weight: $font-weight-regular;
								display: inline-block;
								opacity: 1;
								margin-right: 0;
							}
						}

						svg {
							opacity: 0;
							height: 15px;
							margin: 0;
							transition: opacity .3s ease;
							width: 30px;

							@include mq(mobile) {
								margin-left: auto;
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
}
