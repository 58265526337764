#meet-the-team {
	.section-hero {
		@extend %common-hero;

		background-position: center center, center center;
		background-repeat: no-repeat, no-repeat;
		background-size: cover, 0px;

		@include mq(mobile) {
			background-size: 0px, cover;
			background-position: center, bottom;
		}
	}

	.section-meet-the-team, .section-board-members {
		padding-bottom: 100px;

		.sub-content {
			@extend %container;

			& > .sub-intro-copy {
				@extend %intro-copy-layout;

				margin-bottom: 70px;
			}

			.list-the-team {
				@extend %list-default;

				display: flex;
				flex-wrap: wrap;

				@include mq(mobile) {
					flex-direction: column;
				}


				& > li {
					margin-bottom: 35px;
					margin-right: 45px;
					width: calc(25% - 34px);

					@include mq(tablet-down) {
						width: calc(100% / 3 - 30px);

						&:nth-child(3n+3) {
							margin-right: 0;
						}
					}


					@include mq(mobile) {
						margin-bottom: 50px;
						margin-right: 0;
						text-align: center;
						width: 100%;
					}


					&:nth-child(4n+4) {
						margin-right: 0;

						@include mq(tablet) {
							margin-right: 30px;
						}
					}

					.sub-image {
						background-position: center center;
						background-repeat: no-repeat;
						background-size: cover;
						height: 227px;
						margin-bottom: 30px;

						@include mq(mobile) {
							margin: 0 auto 30px;
							max-width: 300px;
							width: 100%;
						}
					}

					h4 {
						margin-bottom: 5px;
					}

					h5 {
						font-size: $font-size-small-med;
						font-weight: $font-weight-regular;
						color: $color-text-light;
						margin-bottom: 20px;
						min-height: 35px;

						@include mq(mobile) {
							font-size: $font-size-med-large;
						}
					}

					.sub-copy {
						.sub-secondary-copy {
							max-height: 0;
							margin-top: 0;
							overflow: hidden;
							transition: max-height .3s ease, margin-top .3s;

							&.mod-active {
								max-height: 3000px;
								margin-top: 20px;
								overflow: visible;
							}
						}

						.sub-read-more {
							display: block;
							margin-top: 30px;
						}
					}
				}
			}
		}
	}

	.section-board-members {
		background-color: rgba($color-neutral-dark, 0.08);
		padding: 75px 0 155px;

		.sub-content {
			.sub-intro-copy p {
				color: $color-text-light;
				font-size: $font-size-med-large;
				line-height: 30px;
			}

			.list-the-team > li {
				margin-right: 33px;
				width: calc(25% - 25px);

				.sub-image {
					background-color: #D8D8D8;
				}

				p {
					color: $color-text;
					font-size: 14px;
					line-height: 24px;

					&:only-child, &:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
