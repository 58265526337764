#who-we-are {
	.section-hero {
		@extend %common-hero;

		background-position: center center, center center;
		background-repeat: no-repeat, no-repeat;
		background-size: cover, 0px;

		@include mq(mobile) {
			background-size: 0px, cover;
			background-position: center, bottom;
		}
	}

	.section-info-boxes {
		padding: 80px 0;

		.sub-content {
			@extend %container;

			.list-info-boxes {
				@extend %list-default;

				display: flex;

				@include mq(mobile) {
					flex-direction: column;
				}


				& > li {
					flex: 1;
					margin-right: 30px;

					@include mq(mobile) {
						margin-bottom: 50px;
						margin-right: 0;

						&:last-child {
							margin-bottom: 0;
						}
					}


					&:nth-child(2n+2), &:last-child {
						margin-right: 0;
					}

					.sub-image {
						border-radius: $common-border-radius;
						background-position: center center;
						background-size: cover;
						background-repeat: no-repeat;
						height: 220px;
					}

					.sub-copy {
						margin-top: 45px;

						@include mq(mobile) {
							margin-top: 35px;
							text-align: center;
						}


						ul {
							margin: 20px 0 20px 5px;

							@include mq(tablet-down) {
								margin-left: 0;
							}


							li {
								background-image: url("../assets/images/icon-arrow-right.png");
								background-repeat: no-repeat;
								background-position: 0 4px;
								list-style: none;
								margin-bottom: 15px;
								padding-left: 50px;

								@include mq(tablet-down) {
									margin-bottom: 25px;
									margin-left: 0;
									text-align: left;
								}
							}
						}

						h3 {
							@extend %line-under-heading-thin;

							font-size: $font-size-larger;
							margin-bottom: 50px;

							@include mq(mobile) {
								font-size: $font-size-h4;
								text-align: center;
							}
						}

						p {
							font-size: $font-size-standard;

							@include mq(mobile) {
								line-height: $line-height-large;
							}
						}
					}
				}
			}
		}
	}

	.section-programmes {
		padding: 50px 0 80px;

		@include mq(mobile) {
			padding-top: 10px;
		}


		.sub-content {
			@extend %container;

			& > .sub-intro-copy {
				@extend %intro-copy-layout;
			}

			.list-programmes {
				@extend %list-default;

				margin-top: 55px;

				& > li {
					align-items: center;
					border-radius: $common-border-radius;
					box-shadow: 2px 8px 15px 4px rgba(0, 0, 0, 0.09);
					display: flex;
					margin-bottom: 20px;
					padding: 30px 25px;

					.sub-image {
						margin-right: 65px;
						min-width: 94px;
						max-width: 94px;

						@include mq(mobile) {
							display: none;
						}


						svg {
							height: auto;
							width: 100%;

							&.LEPS_Icon {
								height: 65px;
								width: 94px;
							}

							&.Local_Auth_Icon {
								height: 78px;
								width: 94px;
							}

							&.Further_Ed_Icon {
								height: 47px;
								width: 94px;
							}

							&.Not_Profit_Icon {
								height: 59px;
								width: 66px;
							}

							&.Gov_Departments_Icon {
								height: 65px;
								width: 86px;
							}
						}
					}

					.sub-copy {
						width: 100%;

						h4 {
							margin-bottom: 10px;
						}

						.sub-intro-copy {
							display: inline-block;
							margin-right: 10px;
						}

						.sub-full-copy {
							max-height: 0;
							overflow: hidden;
							transition: all .3s ease;

							&.mod-active {
								max-height: 200px;
								margin-top: 20px;
								overflow: visible;
							}
						}

						.sub-read-more {
							display: block;
							margin-top: 10px;
						}
					}
				}
			}
		}
	}

	.section-accreditations {
		@extend %accreditations-block;

		margin-bottom: 105px;
	}
}
