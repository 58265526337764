.modal {
	background: rgba($color-black, 0.4);
	height: 100%;
	position: fixed;
	transition: opacity .2s ease, visibility .2s ease;
	top: 0;
	width: 100%;
	z-index: 0;

	&.modal-open {
		display: block;
		opacity: 1;
		visibility: visible;
		z-index: 10;

		.modal-content {
			opacity: 1;
			visibility: visible;
		}
	}

	.modal-content {
		background-color: $color-white;
		border-radius: $common-border-radius;
		left: 50%;
		height: auto;
		max-height: 60%;
		max-width: 710px;
		overflow: hidden;
		overflow-y: scroll;
		padding: 50px 45px 35px;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 95%;

		@include mq(mobile) {
			display: flex;
			flex-direction: column;
			padding: 30px;
		}


		@include mq(tablet-down) {
			max-height: 70%;
		}


		@include mq(mobile) {
			width: 95%;
			max-height: 80%;
			overflow: scroll;
		}


		.sub-title {
			@extend %line-under-heading;

			font-size: $font-size-h3;

			@include mq(mobile) {
				order: 1;

				&:before {
					left: 14px;
				}
			}
		}

		.sub-intro-info {
			@extend %list-default;

			display: flex;
			margin-bottom: 25px;
			margin-top: 50px;

			& > li {
				&:first-child {
					margin-right: 10px;
				}

				strong {
					color: $color-text-light;
					font-weight: $font-weight-regular;
				}
			}
		}

		.sub-intro-copy {
			margin-bottom: 25px;
		}

		.sub-section {
			margin-bottom: 25px;

			&.sub-approach {
				ul {
					margin: 20px;
				}
			}
		}

		h3 {
			font-size: $font-size-small-med;
			margin-bottom: 5px;
		}

		.sub-logo {
			height: 55px;
			position: absolute;
			right: 45px;
			top: 40px;

			@include mq(mobile) {
				display: none;
			}


			&.mod-mobile {
				@include mq(mobile) {
					display: block !important;
					margin-bottom: 30px;
					position: static;
				}
			}

			img {
				height: 100%;
			}
		}

		.sub-testimonials {
			.sub-testimonial-title {
				font-weight: $font-weight-semibold;
				margin-bottom: 5px;
			}

			.sub-copy {
				margin-bottom: 20px;
				padding-right: 30px;

				@include mq(mobile) {
					padding-right: 10px;
				}


				p {
					color: $color-text-lighter;
					font-size: $font-size-standard;
					font-style: italic;
					font-weight: $font-weight-semibold;

					@include mq(mobile) {
						font-size: $font-size-med;
					}
				}
			}

			span {
				display: block;
				font-weight: $font-weight-semibold;
				margin-bottom: 0;
			}
		}

		.sub-main-image {
			border-radius: 0 0 $common-border-radius $common-border-radius;
		}

		.sub-read-more {
			display: none;
		}

		.mod-hidden-card-content {
			display: block;
		}
	}

	.modal-close {
		background: $color-white;
		border-radius: 50%;
		cursor: pointer;
		height: 54px;
		position: absolute;
		right: 260px;
		top: 170px;
		transition: transform .5s ease;
		width: 54px;

		@include mq(tablet) {
			right: 140px;
			top: 30px;
		}


		@include mq(mobile) {
			right: 3px;
			top: 10px;
		}


		.close-bar-1, .close-bar-2 {
			background-color: $color-secondary;
			height: 2px;
			left: 11px;
			margin-bottom: 12px;
			position: absolute;
			top: 10px;
			transform: translateY(17px) rotate(45deg);
			width: 60%;

			&.close-bar-2 {
				transform: translateY(17px) rotate(-45deg);
			}
		}

		&:hover {
			transform: rotate(90deg);
		}
	}

	&.mod-read-more-content-modal-hidden {
		display: none;
	}
}
