#contact {
	.section-contact {
		.sub-content {
			@extend %container;

			display: flex;
			max-width: 1200px;

			@include mq(mobile) {
				flex-direction: column;
				text-align: center;
				width: 100%;
			}


			.sub-contact-details {
				background: rgba($color-neutral-dark, 0.08);
				flex: 0 0 50%;
				padding: 112px 113px 200px 0;
				position: relative;

				@include mq(mobile) {
					padding: 60px 0;
				}


				&:before {
					content: '';
					background: rgba($color-neutral-dark, 0.08);
					height: 100%;
					left: -100%;
					position: absolute;
					top: 0;
					width: 100%;
				}

				h1 {
					@extend %line-under-heading;

					font-weight: $font-weight-semibold;
					margin-bottom: 45px;

					@include mq(mobile) {
						font-size: 28px;
					}
				}

				.sub-copy {
					@include mq(mobile) {
						padding: 0 50px;

						p {
							line-height: $line-height-large;
						}
					}
				}

				p {
					color: $color-text-light;
					font-size: $font-size-med-large;

					@include mq(mobile) {
						font-size: 13px;
					}
				}

				.sub-image {
					margin-top: 75px;

					@include mq(mobile) {
						margin-top: 50px;
					}
				}

				.sub-details {
					margin-top: 50px;

					@include mq(mobile) {
						margin-top: 40px;
					}


					.sub-number, .sub-email {
						font-size: $font-size-h4;
						font-weight: $font-weight-regular;

						@include mq(mobile) {
							font-size: $font-size-med;
						}


						a {
							color: $color-text;
							font-weight: $font-weight-semibold;
						}
					}

					.sub-address {
						line-height: $line-height-large;
						margin-top: 30px;

						p {
							font-size: $font-size-small-med;

							@include mq(mobile) {
								font-size: $font-size-med;
							}
						}
					}
				}
			}

			.sub-form {
				flex: 0 0 50%;
				padding: 112px 0 200px 60px;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					flex: none;
					width: 50%;
				}

				@include mq(mobile) {
					padding: 45px 20px;
				}


				h2 {
					font-size: $font-size-h4;
					font-weight: $font-weight-bold;
					margin-bottom: 20px;

					@include mq(mobile) {
						font-size: $font-size-larger;
					}
				}

				p {
					color: $color-text;
					font-size: $font-size-standard;
				}

				form {
					margin-top: 50px;

					.sub-errors {
						color: $color-error;
						display: none;
						margin-bottom: 20px;

						.sub-error {
							font-size: $font-size-med;
						}

						span {
							display: block;
							margin-bottom: 3px;
						}
					}

					.sub-terms {
						align-items: flex-start;
						display: flex;
						margin-top: 30px;
						position: relative;

						input {
							opacity: 0;
							width: 0;
						}

						.sub-checkbox {
							cursor: pointer;
							margin-left: -28px;
							padding-left: 20px;
						}

						&:before {
							background: $color-white;
							border: 1px solid $color-borders-light;
							content: '';
							display: inline-block;
							height: 13px;
							margin-right: 9px;
							margin-top: 4px;
							transition: background .3s ease;
							min-width: 13px;

							@include mq(mobile) {
								height: 20px;
								min-width: 20px;
							}
						}

						&:after {
							content: '';
							opacity: 0;
							transition: opacity .3s ease;
						}

						&.mod-checked {
							&:before {
								background: $color-secondary;
								border-color: $color-secondary;
							}

							&:after {
								border: solid $color-white;
								border-width: 0 2px 2px 0;
								display: block;
								height: 8px;
								left: 5px;
								opacity: 1;
								position: absolute;
								top: 5px;
								transform: rotate(45deg);
								width: 3px;

								@include mq(mobile) {
									height: 12px;
									left: 7px;
									width: 6px;
								}
							}
						}

						input {
							margin-top: 4px;
						}

						label {
							align-items: flex-start;
							display: flex;

							@include mq(mobile) {
								text-align: left;
							}


							input {
								margin-right: 20px;
							}

							p {
								color: $color-text-lighter;
								font-size: $font-size-tiny;
								margin-bottom: 20px;

								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}

					button {
						height: 50px;
						max-width: 160px;
						margin-top: 50px;
						padding: 13px 0;

						@include mq(mobile) {
							font-size: $font-size-standard;
							margin: 50px auto 0;
							max-width: 130px;
							padding: 9px 0;
						}
					}
				}

				.wrap-thanks {
					font-size: $font-size-larger;
					display: none;
					margin-top: 50px;
				}
			}
		}
	}

	.footer-main {
		margin-top: 0;
	}
}
