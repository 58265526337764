#case-studies {
	.section-hero {
		@extend %common-hero-large;

		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-top: 0;
		position: relative;

		&:before {
			content: '';
			background: linear-gradient(90deg, rgba(255, 255, 255, 0.73) 0%, rgba(255, 255, 255, 0) 100%);
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		.sub-copy {
			max-width: none;
			position: relative;
			z-index: 99;

			h1 {
				font-size: 38px;
				line-height: 48px;
				margin-bottom: 65px;
				max-width: 560px;
				width: 100%;

				@include mq(mobile) {
					font-size: $font-size-h2;
					line-height: 38px;
					text-align: center;
				}


				&:before {
					bottom: -30px;
					width: 45px;
				}
			}

			h2 {
				font-size: $font-size-large;
				font-weight: $font-weight-medium;

				@include mq(mobile) {
					display: none;
				}


				.sub-seperator {
					color: $color-primary;
					display: inline-block;
					margin: 0 10px;
				}
			}

			a.btn {
				font-size: $font-size-small-med;
				margin-top: 50px;
				max-width: 160px;

				@include mq(mobile) {
					margin: 100px auto 0;
					padding: 15px 0;
				}
			}
		}
	}

	.header-filters {
		padding: 60px 0;

		@include mq(mobile) {
			padding: 40px 0;
		}


		.sub-content {
			@extend %container;

			align-items: center;
			display: flex;

			@include mq(mobile) {
				align-items: flex-start;
				flex-direction: column;
			}


			h3 {
				font-size: $font-size-h2;
				font-weight: $font-weight-bold;
				margin-bottom: 0;

				@include mq(mobile) {
					display: block;
					margin-bottom: 10px;
					text-align: left;
					width: 100%;
				}
			}

			.sub-filters {
				align-items: center;
				display: flex;
				margin-left: auto;

				@include mq(mobile) {
					align-items: flex-start;
					flex-direction: column;
					margin-left: 0;
					margin-top: 20px;
					width: 100%;
				}


				span {
					color: $color-text;
					display: inline-block;
					font-weight: $font-weight-semibold;
					margin-right: 22px;

					@include mq(mobile) {
						display: block;
						margin-bottom: 10px;
					}
				}

				.sub-select-wrap {
					position: relative;
					width: 280px;

					@include mq(mobile) {
						width: 100%;
					}


					&:not(:last-of-type) {
						margin-right: 10px;

						@include mq(mobile) {
							margin-bottom: 10px;
							margin-right: 0;
						}
					}

					svg {
						height: 15px;
						position: absolute;
						right: 20px;
						top: 50%;
						transform: translateY(-50%);
						width: 15px;

						@include mq(mobile) {
							width: 12px;
						}
					}

					select {
						appearance: none;
						background-color: $color-white;
						border-radius: 5px;
						border: 1px solid $color-borders;
						color: $color-text;
						font-size: $font-size-med;
						height: 40px;
						padding: 0 10px;
						width: 100%;
					}
				}
			}
		}
	}

	.section-case-studies {
		.sub-content {
			@extend %container;

			h2 {
				font-size: $font-size-larger;
				font-weight: $font-weight-semibold;
				margin: 0 auto 100px;
				max-width: 85%;
				text-align: center;
			}

			.sub-intro-copy {
				@extend %container-small;

				text-align: center;
			}
		}
	}
}

#case-studies, #case-studies-detail {
	ul.list-case-studies {
		@extend %list-default;

		display: flex;
		flex-wrap: wrap;

		@include mq(mobile) {
			flex-direction: column;
			margin-top: 30px;
		}


		& > li {
			border-radius: 13px;
			box-shadow: 8px 8px 14px -4px rgba(0, 0, 0, 0.09);
			flex: 0 0 calc(33.3333% - 20px);
			margin-bottom: 30px;
			margin-right: 30px;
			position: relative;
			transition: all .3s ease;

			@include mq(tablet-down) {
				margin-right: 15px;
			}


			@include mq(mobile) {
				margin-bottom: 25px;
				margin-right: 0;
			}


			&:nth-child(3n) {
				margin-right: 0;
			}

			&:hover {
				box-shadow: 8px 8px 14px -4px rgba(0, 0, 0, 0.3);
			}

			.sub-link {
				height: 100%;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 999;

				&:hover {
					+ .sub-image-wrapper .sub-image {
						transform: scale(1.1);
					}

					~ .sub-title .sub-icon svg {
						animation: arrow-anim-right .5s;
						opacity: 1;
					}
				}
			}

			.sub-image-wrapper {
				border-radius: $common-border-radius $common-border-radius 0 0;
				overflow: hidden;

				.sub-image {
					background-color: $color-neutral;
					background-position: center center;
					background-size: cover;
					height: 185px;
					transition: all .3s ease;

					@include mq(tablet-down) {
						height: 180px;
					}


					@include mq(mobile) {
						height: 160px;
					}
				}
			}

			.sub-copy-wrap {
				padding: 20px;

				h3.sub-title {
					font-size: $font-size-h4;
					line-height: 31px;
					margin-bottom: 20px;
					min-height: 62px;

					@include mq(mobile) {
						padding-right: 50px;
					}
				}

				h4, h5 {
					color: $color-text-light;
					font-size: 13px;
					font-weight: $font-weight-regular;
				}

				h4 {
					margin-bottom: 3px;
				}

				h5 {
					.sub-seperator {
						display: inline-block;
						margin: 0 10px;
					}
				}

				.sub-copy {
					margin-bottom: 25px;

					p {
						font-size: 14px;
						font-weight: $font-weight-regular;
						line-height: 24px;

						@include mq(mobile) {
							line-height: $line-height-large;
						}
					}
				}

				.sub-read-more {
					display: block;
					font-size: 14px;
					text-align: left;
				}
			}
		}
	}
}

#case-studies-detail {
	.section-hero {
		@extend %common-hero-large;

		position: relative;

		&:before {
			content: '';
			background: linear-gradient(90deg, rgba(255, 255, 255, 0.73) 0%, rgba(255, 255, 255, 0) 100%);
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		.sub-copy {
			max-width: none;
			position: relative;
			z-index: 99;

			h1 {
				font-size: 38px;
				line-height: 48px;
				margin-bottom: 65px;
				max-width: 560px;
				width: 100%;

				@include mq(mobile) {
					font-size: $font-size-h2;
					line-height: 38px;
					text-align: center;
				}


				&:before {
					bottom: -30px;
					width: 45px;
				}
			}

			h2 {
				font-size: $font-size-large;
				font-weight: $font-weight-medium;

				@include mq(mobile) {
					display: none;
				}


				.sub-seperator {
					color: $color-primary;
					display: inline-block;
					margin: 0 10px;
				}
			}
		}
	}

	.section-impact-numbers {
		@extend %container;

		background-color: rgba($color-neutral-dark, 0.08);
		border-radius: 13px;
		margin: 25px auto 0;
		padding: 25px 0px 35px;

		@include mq(mobile) {
			padding: 25px 40px;
		}


		& > h4 {
			font-weight: $font-weight-bold;
			margin-bottom: 30px;
			text-align: center;

			@include mq(mobile) {
				margin-bottom: 15px;
			}
		}

		ul.list-impact {
			@extend %list-default;

			display: flex;

			@include mq(mobile) {
				flex-direction: column;
			}


			& > li {
				flex: 1;
				padding: 0 10px;
				text-align: center;

				@include mq(mobile) {
					padding: 10px;
				}


				&:nth-child(2) {
					border-left: 1px solid #D8D8D8;
					border-right: 1px solid #D8D8D8;

					@include mq(mobile) {
						border-bottom: 1px solid #D8D8D8;
						border-left: 0;
						border-right: 0;
						border-top: 1px solid #D8D8D8;
					}
				}

				h5 {
					color: $color-primary;
					font-size: $font-size-hero-small;
					font-weight: $font-weight-semibold;
					margin-bottom: 5px;

					@include mq(mobile) {
						font-size: $font-size-h1;
					}
				}

				p {
					font-size: $font-size-small-med;
					line-height: 24px;
				}
			}
		}
	}

	.section-main-copy {
		padding: 60px 0;

		.sub-content {
			@extend %container;

			display: flex;

			@include mq(mobile) {
				flex-direction: column;
			}


			.sub-main-copy {
				flex: 1;
				max-width: 680px;
				width: 100%;

				@include mq(mobile) {
					max-width: none;
				}


				&:first-of-type {
					margin-right: 65px;

					@include mq(mobile) {
						margin-right: 0;
					}
				}

				h2, h3, h4, h5 {
					font-size: $font-size-h4;
				}

				p {
					font-size: 14px;
					line-height: 24px;
					margin-bottom: 25px;
				}

				iframe {
					border: none;
					height: 265px;
					max-width: 475px;
					margin: 0 auto;
					width: 100%;
				}
			}

			.aside-copy {
				max-width: 475px;
				margin-left: auto;
				width: 100%;

				@include mq(mobile) {
					margin-left: 1px;
					max-width: none;
					margin-top: 50px;
				}


				iframe {
					height: 267px;
					margin-bottom: 22px;
					width: 100%;
				}
			}
		}
	}

	.section-funding {
		padding: 75px 0 130px;

		@include mq(mobile) {
			padding: 30px 0 60px;
		}


		.sub-content {
			@extend %container;

			h3 {
				font-size: $font-size-h2;
				font-weight: $font-weight-bold;
				margin-bottom: 50px;
				text-align: center;
			}

			ul.list-funders {
				@extend %list-default;

				align-items: center;
				display: flex;
				justify-content: center;

				&.mod-desktop {
					@include mq(mobile) {
						display: none;
					}
				}

				&.mod-mobile {
					display: none;

					@include mq(mobile) {
						display: flex;
						padding: 0 30px;

						li img {
							margin: 0 auto;
						}

						.slick-list, .slick-track, .slick-slide {
							width: 100% !important;
						}

						.slick-slide div li {
							margin: 0 !important;
							padding: 0 !important;
						}
					}
				}

				li {
					margin-right: 60px;

					&:last-child {
						margin-right: 0;
					}

					img {
						max-height: 120px;
						max-width: 320px;
					}
				}
			}

			.sub-logos {
				position: relative;
			}

			ul.nav-slider {
				@extend %list-default;

				display: none;

				@include mq(mobile) {
					display: flex;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					width: 100%;

					svg {
						cursor: pointer;
						height: 15px;
						width: 30px;
					}

					.sub-prev {
						svg {
							transform: rotate(180deg);
						}
					}

					.sub-next {
						margin-left: auto;
					}
				}
			}
		}
	}

	.section-cta {
		height: 120px;
		margin: 40px 0;

		@include mq(mobile) {
			height: auto;
		}


		.sub-content {
			@extend %container;

			align-items: center;
			background-color: rgba(#526B79, 0.08);
			border-radius: 13px;
			display: flex;
			height: 100%;
			justify-content: center;
			text-align: center;

			@include mq(mobile) {
				padding: 20px 30px;
			}


			p {
				color: $color-text;
				font-size: $font-size-h2;
				font-weight: $font-weight-bold;
				margin-bottom: 0;

				@include mq(mobile) {
					font-size: $font-size-h3;
					font-weight: $font-weight-semibold;
					line-height: 32px;
				}
			}

			a {
				color: $color-secondary;
			}
		}
	}

	.section-related-case-study {
		padding: 92px 0;

		@include mq(mobile) {
			padding: 80px 0 20px;
		}


		.sub-content {
			@extend %container;

			& > h3 {
				font-size: $font-size-h2;
				font-weight: $font-weight-bold;
				margin-bottom: 50px;
				text-align: center;

				@include mq(mobile) {
					font-size: $font-size-h3;
				}
			}
		}
	}
}
