.header-main {
	align-items: center;
	background: $color-white;
	display: flex;
	height: 97px;

	@include mq(tablet-down) {
		height: 60px;
		position: relative;
	}


	.sub-container {
		@extend %container;

		display: flex;
		position: relative;
		width: 95%;

		@include mq(tablet-down) {
			max-width: none;
			width: 100%;
		}


		.logo {
			@include mq(tablet-down) {
				align-items: center;
				display: flex;
				padding-left: 20px;
			}


			img {
				@include mq(tablet-down) {
					height: 30px;
				}
			}
		}

		nav {
			align-items: center;
			display: flex;
			margin-left: auto;

			@include mq(tablet-down) {
				background: $color-white;
				display: block;
				height: 100vh;
				overflow: scroll;
				position: absolute;
				top: 0;
				visibility: hidden;
				width: 100%;
				z-index: -1;
			}


			ul.list-nav {
				@extend %list-default;

				position: relative;

				@include mq(tablet-down) {
					flex-direction: column;
					height: 100vh;
					overflow: scroll;
					padding: 80px 20px;
					width: 100%;
				}


				li {
					display: inline-block;
					padding: 0 45px 0 0;
					position: relative;

					@include mq(tablet-down) {
						clear: both;
						flex-direction: column;
						height: auto;
						text-align: center;
						padding: 0;
						width: 100%;

						&:first-child {
							padding-top: 10px;

							@include mq(tablet-down) {
								padding-top: 0;
							}
						}
					}


					&.mod-dropdown {
						a {
							cursor: pointer;
						}
					}

					&:hover ul {
						opacity: 1;
					}

					& > a {
						color: $color-text;
						font-size: $font-size-small-med;
						font-weight: $font-weight-bold;

						@include mq(tablet-down) {
							display: block;
							font-size: $font-size-large;
							padding: 25px 0;
						}
					}

					& > ul {
						background-color: $color-white;
						border-radius: 5px;
						box-shadow: 4px 6px 15px 1px rgba(0, 0, 0, 0.12);
						left: -50px;
						margin: 0;
						opacity: 0;
						padding: 10px 15px;
						position: absolute;
						top: 50px;
						width: 208px;
						z-index: 999;

						@include mq(tablet-down) {
							box-shadow: none;
							left: 0;
							max-height: 0;
							margin-top: -10px;
							opacity: 1;
							overflow: hidden;
							padding: 0;
							position: static;
							top: auto;
							transition: max-height .3s ease;
							width: 100%;
						}


						&:before {
							background: transparent;
							content: '';
							height: 30px;
							left: 33px;
							position: absolute;
							top: -30px;
							width: 60%;
						}

						&.mod-active {
							@include mq(tablet-down) {
								max-height: 600px;
							}
						}

						& > li {
							display: block;
							margin: 0;
							padding: 0;

							&:last-child a {
								border-bottom: 0;
							}

							a {
								border-bottom: 1px solid #E8EBED;
								display: block;
								padding: 13px 0;
								transition: color .3s ease;
								width: 100%;

								@include mq(tablet-down) {
									border-bottom: 0;
									font-size: $font-size-med;
								}


								&:hover {
									color: $color-primary;
								}
							}
						}
					}
				}

				.nav-track-line {
					background-color: $color-secondary;
					bottom: -15px;
					display: block;
					height: 3px;
					position: absolute;
					transition: all 0.5s cubic-bezier(1, 0.07, 0, 0.95);
					width: 19px;

					@include mq(tablet-down) {
						display: none;
					}
				}
			}
		}

		.sub-search {
			align-items: center;
			cursor: pointer;
			display: flex;

			@include mq(tablet-down) {
				position: absolute;
				right: 60px;
				top: 4px;
				z-index: 999;
			}


			.sub-searchform {
				opacity: 0;
				position: absolute;
				right: -2px;
				top: 0;
				transition: width .3s ease, opacity .2s .1s ease;
				width: 0;
				z-index: -99;

				input {
					height: 55px;
					border: 1px solid transparent;
					border-radius: 30px;
					background-color: $color-white;
					box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.1);
					outline: none;
					padding: 0;
					transition: padding .3s ease, border .001s;
					width: 100%;
				}

				&.mod-active {
					opacity: 1;
					width: 530px;
					z-index: 99;

					& > input {
						border: 1px solid $color-borders;
						padding: 0 30px;
						width: 100%;
					}

					.sub-close {
						opacity: 1;
					}
				}

				.sub-close {
					cursor: pointer;
					height: 100%;
					opacity: 0;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					transition: opacity .3s ease;
					width: 50px;

					&:before, &:after {
						background: $color-secondary;
						content: '';
						display: block;
						height: 2px;
						position: absolute;
						right: 22px;
						top: 24px;
						transform: rotate(45deg);
						width: 20px;
					}

					&:after {
						transform: rotate(-45deg);
					}
				}
			}

			svg {
				color: $color-text-lighter;
				height: 22px;
				width: 22px;

				@include mq(tablet-down) {
					color: $color-text;
				}
			}
		}

		.sub-searchform.mod-mobile {
			display: none;

			@include mq(tablet-down) {
				&.mod-active {
					background: $color-white;
					display: block;
					height: 100vh;
					left: 0;
					position: absolute;
					top: 0;
					width: 100vw;
					z-index: 999;
				}

				input {
					border: none;
					color: $color-text;
					font-size: $font-size-h4;
					font-weight: $font-weight-light;
					margin-top: 110px;
					outline: none;
					text-align: center;
					width: 100vw;
				}

				.sub-close {
					cursor: pointer;
					height: 20px;
					position: absolute;
					right: 20px;
					top: 20px;
					width: 25px;
					z-index: 9999;

					&:before, &:after {
						background: $color-secondary;
						content: '';
						display: block;
						height: 2px;
						position: absolute;
						right: 0;
						top: 10px;
						transform: rotate(45deg);
						width: 25px;
					}

					&:after {
						transform: rotate(-45deg);
					}
				}
			}
		}
	}

	.mob-hamburger {
		@include mq(tablet-down) {
			cursor: pointer;
			display: flex;
			flex-direction: column;
			height: 20px;
			justify-content: center;
			overflow: hidden;
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			transition: width .3s ease;
			width: 23px;
			z-index: 999;

			.mob-bar {
				background: $color-text;
				border-radius: 20px;
				display: block;
				height: 2px;
				left: 0;
				margin-bottom: 8px;
				opacity: 1;
				position: absolute;
				transform: rotate(0deg);
				transition: .4s ease-in-out;
				width: 100%;
				z-index: 9999;
			}

			.mob-bar-1 {
				top: 0;
			}

			.mob-bar-2 {
				top: 8px;
			}

			.mob-bar-3 {
				margin-bottom: 0;
				top: 16px;
			}

			&.mod-active {
				width: 28px;

				.mob-bar-1 {
					top: 9px;
					transform: rotate(135deg);
				}

				.mob-bar-2 {
					left: -60px;
					opacity: 0;
				}

				.mob-bar-3 {
					transform: rotate(-135deg);
					top: 9px;
					width: 100%;
				}

				& + #nav {
					visibility: visible;
					width: 100%;
					z-index: 199;
				}
			}
		}
	}
}

.section-cookies {
	align-items: center;
	background: $color-neutral-dark;
	display: flex;
	padding: 20px 0;
	width: 100%;
	z-index: 999;

	@include mq(tablet-down) {
		height: auto;
		padding: 10px 0 20px;
	}


	&.mod-hide {
		display: none;
	}

	.sub-content {
		@extend %container-large;

		display: flex;

		@include mq(tablet-down) {
			flex-direction: column;
		}


		.sub-text {
			flex: 1;

			& > p {
				color: $color-white;
				font-size: $font-size-small;
				line-height: $line-height-base;
				margin-bottom: 0;

				@include mq(tablet-down) {
					color: rgba($color-white, 0.7);
				}


				a {
					color: $color-white;
					font-weight: $font-weight-semibold;

					@include mq(tablet-down) {
						color: rgba($color-white, 0.7);
					}
				}
			}
		}

		.sub-btns {
			align-items: center;
			display: flex;
			margin-left: 70px;

			@include mq(tablet-down) {
				margin-left: 0;
				margin-top: 20px;
			}


			.btn {
				background: none;
				border: 2px solid rgba($color-white, 0.25);
				color: $color-white;
				display: inline-block;
				font-size: $font-size-standard;
				font-family: $headings-font !important;
				padding: 5px 0;
				transition: background .3s ease;
				width: 130px;

				@include mq(tablet-down) {
					font-size: $font-size-small;
					padding: 4px 0;
					width: 115px;
				}


				&:first-of-type {
					margin-right: 10px;
				}

				&:hover {
					background: rgba($color-white, 0.25);
				}
			}
		}
	}
}

.sub-results {
	background: rgba($color-white, 0.95);
	height: calc(100vh - 97px);
	left: 0;
	opacity: 0;
	position: absolute;
	padding-top: 50px;
	top: 97px;
	transition: z-index .05s ease, opacity .3s ease;
	width: 100vw;
	z-index: -1;
	display: none;

	@include mq(tablet-down) {
		height: calc(100vh - 155px);
		padding-top: 10px;
		top: 155px;
	}


	&.mod-show {
		opacity: 1;
		z-index: 9999;
		display: block;
	}

	.list-results {
		@extend %list-default, %container-small;

		height: 60%;
		overflow: hidden;
		overflow-y: scroll;
		margin: 0 auto;
		max-width: 900px;
		width: 90%;

		@include mq(tablet-down) {
			height: 61vh;
		}


		& > li {
			border-bottom: 1px solid $color-borders;
			display: flex;

			.title {
				flex: 1;
				padding: 30px 50px 30px 15px;

				@include mq(tablet-down) {
					padding: 15px 50px 15px 0;
				}
			}

			.category {
				align-self: center;
				text-transform: capitalize;
				padding-right: 15px;
			}
		}
	}

	.sub-loading {
		background: $color-white;
		display: none;
		height: 550px;
		left: 50%;
		max-width: 700px;
		position: absolute;
		text-align: center;
		top: 0;
		transform: translateX(-50%);
		width: 100%;
		z-index: 9999;

		img {
			position: absolute;
			top: 50%;
			width: 60px;
		}
	}
}
