.footer-main {
	background: $color-white;
	border-bottom: 1px solid rgba($color-borders, 0.29);
	border-top: 1px solid rgba($color-borders, 0.29);
	margin-top: 120px;
	padding-bottom: 70px;
	z-index: 1;

	@include mq(mobile) {
		margin-top: 80px;
	}

	.sub-content {
		@extend %container;

		padding: 30px 0;
		position: relative;

		.ngage-20-logo {
			position: absolute;
			right: 0;
			top: 40px;

			img {
				width: 135px;

				@include mq(mobile) {
					width: 95px;
				}
			}
		}

		svg {
			margin-right: 25px;
			vertical-align: text-bottom;

			&.icon-youtube {
				height: 19px;
				width: 26px;

				@include mq(mobile) {
					bottom: 155px;
					position: absolute;
					right: 20px;
				}
			}

			&.icon-linkedin {
				color: $color-text-lighter;

				@include mq(mobile) {
					bottom: 155px;
					position: absolute;
					right: -20px;
				}
			}
		}

		.nav-footer {
			@extend %list-default;

			align-items: center;
			display: flex;
			margin-bottom: 40px;

			@include mq(mobile) {
				align-items: flex-start;
				flex-direction: column;
			}

			& > li {
				font-size: $font-size-standard;
				font-weight: $font-weight-bold;
				margin-right: 40px;

				@include mq(mobile) {
					font-weight: $font-weight-semibold;
					margin-bottom: 20px;
				}

				&:last-of-type {
					margin-right: 0;

					@include mq(mobile) {
						margin-bottom: 0;
					}
				}

				& > a {
					color: $color-text-light;

					@include mq(mobile) {
						font-size: $font-size-small;
					}
				}

				.btn {
					border-color: $color-secondary;
					color: $color-white;
					min-height: 50px;
					width: 160px;

					@include mq(mobile) {
						font-size: $font-size-small;
						margin-bottom: 15px;
						min-height: 37px;
						width: 122px;
					}
				}
			}
		}

		.sub-socials {
			@extend %list-default;

			& > li {
				display: inline-block;
			}
		}

		.sub-copyright {
			color: $color-text-lighter;
			font-size: $font-size-tiny;

			@include mq(mobile) {
				display: block;
			}

			&:after {
				content: "|";
				font-size: 13px;
				margin: 0px 12px 0 16px;
				vertical-align: middle;

				@include mq(mobile) {
					content: none;
				}
			}
		}

		ul.list-footer-links {
			@extend %list-default;

			display: inline-block;

			& > li {
				display: inline-block;

				a {
					color: $color-text-lighter;
					font-size: $font-size-tiny;

					&:after {
						content: "|";
						font-size: 13px;
						margin: 0px 12px;
						vertical-align: middle;
					}
				}

				&:last-child a:after {
					content: none;
				}
			}
		}

		.sub-address {
			color: rgba($color-text-light, 0.5);
			font-size: 9px;
			padding-top: 15px;
		}
	}
}
