/* COLOURS */
$color-black: #000000;
$color-white: #ffffff;

$color-body: $color-white;

$color-neutral: #F5F2F5;
$color-neutral-dark: #526B79;

$color-borders: #E0E0E0;
$color-borders-light: #c8c8c8;

$color-text: #303030;
$color-text-light: #686868;
$color-text-lighter: #878786;

$color-headings: #263238;

$color-error: #ff0000;

$color-primary: #E61F6B;
$color-secondary: #EF7F13;

$color-buttons: $color-secondary;

$color-link: $color-secondary;
$color-link-hover: lighten($color-link, 5%);
$color-link-active: $color-link-hover;

$color-validation-error: #cd0000;
$color-validation-success: #4eb73c;

/* FONTS */
$font-standard: "proxima-nova", Helvetica, Sans-serif;

$font-base: 14;

$font-size-base: $font-base * 1px;
$font-size-standard: 16 / $font-base * 1rem;
$font-size-tiny: 10 / $font-base * 1rem;
$font-size-small: 12 / $font-base * 1rem;
$font-size-small-med: 15 / $font-base * 1rem;
$font-size-med: 16 / $font-base * 1rem;
$font-size-med-large: 17 / $font-base * 1rem;
$font-size-large: 18 / $font-base * 1rem;
$font-size-larger: 23 / $font-base * 1rem;
$font-size-largest: 30 / $font-base * 1rem;

$font-size-h1: 36 / $font-base * 1rem;
$font-size-h2: 28 / $font-base * 1rem;
$font-size-h3: 24 / $font-base * 1rem;
$font-size-h4: 20 / $font-base * 1rem;
$font-size-h5: $font-size-med;
$font-size-h6: $font-size-standard;

$font-size-hero-standard: $font-size-larger;
$font-size-hero-small: 42 / $font-base * 1rem;
$font-size-hero-med: 50 / $font-base * 1rem;
$font-size-hero-large: 65 / $font-base * 1rem;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$headings-font: $font-standard;
$headings-font-weight: $font-weight-bold;
$headings-color: $color-headings;
$headings-line-height: 1.4;

$line-height-base: 1.55;
$line-height-med: 1.25;
$line-height-large: 1.75;

$radius-button: 3px;
$padding-button: 14px 0;
$padding-button-smaller: 12px 0;
$text-size-button: $font-size-standard;

/* STRUCTURE */
$viewport-width: 100%;
$container-width: 95%;
$container-max-width: 1380px;
$container-max-width-small: 750px;
$container-max-width-large: 1300px;
$common-border-radius: 22px;

/* MOBILE STRUCTURE */
$container-width-mobile: 92%;
$container-gutter-mobile: 10px;
$container-max-width-mobile: calc(100% - #{$container-gutter-mobile});

/* MEDIA QUERY */
$mq-desktop-size: $container-max-width;
$mq-tablet-size: 1024px;
$mq-mobile-size: 850px;
