#track-record {
	.section-hero {
		@extend %common-hero;
	}

	.section-differences {
		padding: 80px 0;

		@include mq(mobile) {
			padding: 50px 0;
		}


		.sub-content {
			@extend %container;

			& > .sub-intro-copy {
				@extend %intro-copy-layout;

				margin-bottom: 70px;
			}

			ul.list-differences {
				@extend %common-listings-with-shadow;

				& > li .sub-image {
					svg {
						width: 127px;

						&.Track_Record_Skills {
							width: 105px;
						}
					}

					&.mod-desktop {
						@include mq(tablet-down) {
							display: none;
						}
					}

					&.mod-mobile {
						display: none;

						@include mq(tablet-down) {
							display: block;
						}
					}
				}
			}
		}
	}

	.section-projects {
		.sub-content {
			@extend %container;

			h2 {
				text-align: center;
			}

			.sub-intro-copy {
				@extend %container-small;

				text-align: center;
			}

			ul.list-projects {
				@extend %list-default;

				display: flex;
				margin-top: 70px;

				@include mq(mobile) {
					flex-direction: column;
				}


				& > li {
					border-radius: $common-border-radius;
					box-shadow: 8px 8px 14px -4px rgba(0, 0, 0, 0.09);
					flex: 1;
					margin-right: 30px;
					position: relative;
					transition: all .3s ease;

					@include mq(mobile) {
						margin-bottom: 25px;
						margin-right: 0;
					}


					&:hover {
						box-shadow: 8px 8px 14px -4px rgba(0, 0, 0, 0.3);
					}

					.sub-link {
						height: 100%;
						position: absolute;
						top: 0;
						width: 100%;
						z-index: 999;

						&:hover {
							+ .sub-image-wrapper .sub-image {
								transform: scale(1.02);
							}

							~ .sub-title .sub-icon svg {
								animation: arrow-anim-right .5s;
								opacity: 1;
							}
						}
					}

					.sub-title {
						align-items: center;
						display: flex;
						margin: 25px 0 15px;
						overflow: hidden;
						padding: 0 25px;

						h3 {
							font-size: $font-size-larger;
							margin-bottom: 0px;

							@include mq(mobile) {
								font-size: $font-size-h4;
								padding-right: 50px;
							}
						}

						.sub-icon {
							overflow: hidden;
							margin-left: auto;
							max-width: 31px;
							width: 100%;

							svg {
								opacity: 0;
								height: 15px;
								margin: 0;
								transition: opacity .3s ease;
								width: 30px;

								@include mq(mobile) {
									opacity: 1;
								}
							}
						}
					}

					.sub-copy {
						padding: 0 25px 40px;

						@include mq(mobile) {
							padding-right: 35px;
						}


						p {
							font-size: $font-size-standard;
							font-weight: $font-weight-regular;
						}
					}

					.sub-image-wrapper {
						border-radius: $common-border-radius $common-border-radius 0 0;
						overflow: hidden;

						.sub-image {
							background-position: center center;
							background-size: cover;
							height: 222px;
							transition: all .3s ease;

							@include mq(mobile) {
								height: 160px;
							}
						}
					}
				}
			}
		}

		.sub-more-projects {
			margin-top: 100px;
			text-align: center;

			@include mq(mobile) {
				margin-top: 50px;
			}


			& > p {
				font-size: $font-size-h4;
			}

			.btn {
				margin: 30px auto 0;
				max-width: 225px;

				@include mq(mobile) {
					font-size: $font-size-tiny;
				}
			}
		}
	}
}
