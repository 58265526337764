// Define placeholders within here to be used within further sass.
%container {
	width: $container-width;
	max-width: $container-max-width;
	margin: 0 auto;

	// Example of how to extend container. Used via `@extend %container-large`
	&-large {
		@extend %container;

		max-width: $container-max-width-large;
	}

	&-small {
		@extend %container;

		max-width: $container-max-width-small;
	}

	@include mq(mobile) {
		width: $container-width-mobile;
		max-width: $container-max-width-mobile;
		margin: 0 auto;
	}
}

%clearfix {
	zoom: 1;

	&:after {
		clear: both;
	}

	&:before,
	&:after {
		content: "";
		display: table;
	}
}

%noselect {
	-webkit-touch-callout: none;
	user-select: none;
}

%nofocus {
	&:focus {
		outline-style: none;
		box-shadow: none;
	}
}

%list-default {
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		margin: 0;
		padding: 0;
	}
}

%common-hero {
	@extend %container;

	align-items: flex-start;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: $common-border-radius;
	display: flex;
	flex-direction: column;
	height: 476px;
	justify-content: center;
	width: 95%;

	@include mq(mobile) {
		border-radius: 0px;
		height: 485px;
		justify-content: flex-start;
		max-width: none;
		padding: 60px 30px;
		width: 100%;
	}


	&-large {
		@extend %common-hero;

		height: 550px;
		justify-content: flex-start;
		padding-top: 120px;

		@include mq(tablet-down) {
			height: 485px;
		}
	}

	.sub-copy {
		max-width: 540px;
		padding-left: 80px;
		width: 100%;

		@include mq(mobile) {
			margin: 0 auto;
			padding-left: 0;
			width: 90%;
		}


		h1 {
			@extend %line-under-heading;

			font-size: $font-size-hero-small;
			font-weight: $font-weight-semibold;
			line-height: $line-height-med;
			margin-bottom: 45px;

			@include mq(mobile) {
				font-size: $font-size-h2;
				text-align: center;
			}
		}

		p {
			color: $color-text-light;
			font-size: $font-size-large;
			font-weight: $font-weight-medium;

			@include mq(mobile) {
				font-size: $font-size-standard;
				line-height: $line-height-large;
				text-align: center;
			}
		}
	}
}

%intro-copy-layout {
	@extend %container-small;

	text-align: center;

	h2 {
		text-align: center;

		@include mq(mobile) {
			font-size: $font-size-h4;
		}
	}
}

%line-under-heading {
	position: relative;

	&:before {
		content: '';
		background: $color-primary;
		display: block;
		height: 4px;
		position: absolute;
		bottom: -20px;
		width: 1em;

		@include mq(mobile) {
			left: 50%;
			transform: translateX(-50%);
			width: 1.2em;
		}
	}

	&-thin {
		@extend %line-under-heading;

		&:before {
			height: 3px;
		}
	}
}

%common-listings {
	@extend %list-default;

	display: flex;

	@include mq(mobile) {
		flex-direction: column;
	}


	&-with-shadow {
		@extend %common-listings;

		border-radius: $common-border-radius;
		box-shadow: 2px 8px 15px 4px rgba($color-black, 0.09);
		padding: 50px 30px;

		@include mq(mobile) {
			border-radius: 0;
			box-shadow: none;
			padding: 20px 30px;
		}


		& > li {
			margin-right: 70px !important;

			@include mq(tablet-down) {
				margin-right: 45px !important;
			}


			@include mq(mobile) {
				margin-right: 0 !important;
			}
		}

		.sub-copy {
			position: relative;

			&:after {
				content: '';
				background: #EFEFEF;
				display: block;
				height: 62px;
				position: absolute;
				right: -36px;
				top: 4px;
				width: 1px;
			}
		}
	}

	& > li {
		flex: 1;
		margin-right: 35px;
		text-align: center;

		@include mq(mobile) {
			margin-bottom: 50px;
			margin-right: 0;
		}


		&:last-child {
			margin-right: 0 !important;
		}

		.sub-image {
			margin-bottom: 35px;

			svg {
				&.Careers_Homeworking {
					height: 109px;
					width: 86px;
				}

				&.Careers_Inclusive {
					height: 102px;
					width: 136px;
				}

				&.Careers_Talent {
					height: 112px;
					width: 118px;
				}

				&.Careers_Flexi {
					height: 122px;
					width: 90px;
				}

				&.Careers_annual_Leave {
					height: 122px;
					width: 114px;
				}

				&.Careers_Benefits {
					height: 104px;
					width: 101px;
				}
			}
		}

		.sub-title {
			font-size: $font-size-h4;

			@include mq(mobile) {
				margin-bottom: 10px;
			}
		}
	}
}

%accreditations-block {
	.sub-content {
		@extend %container;

		border-radius: $common-border-radius;
		display: flex;
		min-height: 565px;

		@include mq(mobile) {
			flex-direction: column;
			min-height: auto;
		}


		.sub-main-copy {
			background: $color-primary;
			border-radius: $common-border-radius 0 0 $common-border-radius;
			display: flex;
			flex: 1;
			flex-direction: column;
			justify-content: center;
			padding: 40px 80px;

			@include mq(tablet-down) {
				padding: 40px 55px;
			}


			@include mq(mobile) {
				border-radius: $common-border-radius $common-border-radius 0 0;
				line-height: $line-height-large;
				padding: 45px 30px;
				text-align: center;
			}


			h2 {
				color: $color-white;
				font-size: $font-size-h2;

				@include mq(mobile) {
					font-size: $font-size-large;
					margin-bottom: 35px;
				}
			}

			.sub-secondary-copy {
				p {
					color: $color-white;
					font-size: $font-size-med;
					font-weight: $font-weight-medium;

					@include mq(mobile) {
						font-size: $font-size-standard;
					}
				}
			}

			.btn {
				font-size: $font-size-small-med;
				font-weight: $font-weight-bold;
				margin-top: 60px;
			}
		}

		.sub-logos {
			align-items: center;
			background-color: #F9F9F9;
			border-radius: 0 $common-border-radius $common-border-radius 0;
			display: flex;
			justify-content: center;
			max-width: 590px;
			width: 100%;

			@include mq(tablet-down) {
				flex: 1;
				max-width: none;
			}


			@include mq(mobile) {
				background: #EFEFEF;
				border-radius: 0 0 $common-border-radius $common-border-radius;
				padding: 30px 20px;
			}


			.list-logos {
				@extend %list-default;

				display: flex;
				flex-wrap: wrap;
				max-width: 70%;
				padding: 20px 0;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					display: block;
				}

				@include mq(tablet-down) {
					max-width: 80%;
				}


				@include mq(mobile) {
					display: block;
					max-width: 100%;
				}


				& > li {
					align-items: center;
					display: flex;
					flex: 0 0 50%;
					justify-content: center;
					max-height: 125px;

					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						display: block;
						flex: none;
						float: left;
					}

					@include mq(tablet) {
						display: block;
					}


					@include mq(mobile) {
						flex: none;
						margin-bottom: 15px;
					}


					&:last-child {
						margin-bottom: 0;
					}

					& > img {
						max-height: 155px;
						transform: scale(0.65);

						&.Living-Wage-Employer {
							width: 140px !important;
						}

						&.GBC-logo {
							width: 130px !important;
						}
					}
				}
			}
		}
	}
}

.mod-hidden-card-content {
	display: none;
}
