#home {
	.section-hero {
		@extend %container;

		align-items: center;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: $common-border-radius;
		display: flex;
		height: 631px;
		justify-content: center;
		position: relative;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			display: block;
			position: relative;
		}

		@include mq(mobile) {
			border-radius: 0px;

			// height: 485px;
			// max-width: none;

			height: auto;
			margin: 0;
			max-width: unset;
			width: 100%;
		}


		.sub-copy {
			margin-left: auto;
			max-width: 600px;
			padding-right: 65px;
			width: 100%;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
			}

			@include mq(mobile) {
				text-align: center;
				padding-right: 0;
			}


			h1 {
				@extend %line-under-heading;

				font-size: $font-size-hero-small;
				font-weight: $font-weight-semibold;
				line-height: $line-height-med;
				margin-bottom: 45px;

				@include mq(mobile) {
					font-size: $font-size-h2;
					padding: 0 10px;
				}
			}

			p {
				color: $color-text-light;
				font-weight: $font-weight-medium;

				@include mq(mobile) {
					font-size: 13px;
					line-height: $line-height-large;
				}
			}
		}

		.hero-nav {
			align-items: center;
			bottom: 35px;
			display: flex;
			justify-content: center;
			position: absolute;
			width: 100%;

			@include mq(mobile) {
				bottom: 20px;
			}


			.sub-dots {
				margin: 0 25px;
			}

			.slick-arrow {
				cursor: pointer;
				transition: transform .3s ease;

				&.sub-prev:hover {
					transform: translateX(-4px);
				}

				&.sub-next:hover {
					transform: translateX(4px);
				}
			}
		}

		.slick-list, .slick-track, .slick-slide {
			height: 100%;
			width: 100%;

			> div {
				height: 100%;
				width: 100%;
			}
		}

		.list-home-hero {
			@extend %list-default;

			display: flex;
			height: 100%;
			width: 100%;

			.item-home-hero {
				align-items: center;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				border-radius: $common-border-radius;
				display: flex !important;
				justify-content: center;
				height: 100%;
				margin: 0;
				width: 100%;

				@include mq(mobile) {
					border-radius: 0px;
					height: 485px;
					max-width: none;
					padding: 0 30px;
					width: 100%;
				}
			}
		}
	}

	.section-what-we-do {
		padding: 75px 0;

		@include mq(mobile) {
			padding: 40px 0;
		}


		.sub-content {
			@extend %container;

			h2 {
				margin-bottom: 25px;
				text-align: center;

				@include mq(mobile) {
					font-size: $font-size-h4;
				}
			}

			.sub-intro-copy {
				@extend %container-small;

				text-align: center;
				width: 100%;

				& > p {
					line-height: $line-height-large;

					@include mq(mobile) {
						color: $color-text-light;
						font-size: $font-size-small-med;
					}
				}
			}

			ul.list-what-we-do {
				@extend %list-default;

				display: flex;
				margin: 70px 0 40px;
				justify-content: center;

				@include mq(tablet) {
					flex-wrap: wrap;
					margin-bottom: 0;
				}


				@include mq(mobile) {
					flex-direction: column;
				}


				& > li {
					flex: 0 0 calc(25% - 75px);
					margin-right: 75px;

					@include mq(tablet) {
						flex: 0 0 calc(50% - 38px);
						margin-bottom: 50px;

						&:nth-child(2n+2) {
							margin-right: 0;
						}
					}


					@include mq(mobile) {
						flex: 1;
						margin-bottom: 50px;
						margin-right: 0;
					}


					&:last-child {
						margin-right: 0;

						@include mq(mobile) {
							margin-bottom: 0;
						}
					}

					.sub-image {
						margin-bottom: 35px;

						@include mq(mobile) {
							margin-bottom: 20px;
							text-align: center;
						}
					}

					h3 {
						@extend %line-under-heading-thin;

						font-size: $font-size-h4;
						margin-bottom: 40px;

						@include mq(mobile) {
							text-align: center;
						}


						&:before {
							bottom: -18px;
						}
					}

					.sub-copy {
						p {
							font-size: $font-size-standard;

							@include mq(mobile) {
								line-height: $line-height-large;
								text-align: center;
							}
						}
					}
				}
			}
		}
	}

	.section-who-we-are {
		margin-bottom: 105px;

		@include mq(mobile) {
			margin-bottom: 65px;
		}


		.sub-content {
			@extend %container;

			border-radius: $common-border-radius;
			display: flex;

			@include mq(mobile) {
				flex-direction: column;
			}


			.sub-main-copy {
				background: $color-primary;
				border-radius: $common-border-radius 0 0 $common-border-radius;
				display: flex;
				flex: 1;
				flex-direction: column;
				justify-content: center;
				padding: 50px 80px;

				@include mq(tablet) {
					padding: 50px 40px;
				}


				@include mq(mobile) {
					border-radius: 0 0 $common-border-radius $common-border-radius;
					order: 1;
					padding: 45px 30px;
					text-align: center;
				}


				h2 {
					color: $color-white;
					font-size: $font-size-h2;

					@include mq(mobile) {
						font-size: $font-size-large;
						margin-bottom: 30px;
					}
				}

				.sub-secondary-copy {
					p {
						color: $color-white;
						font-size: $font-size-large;
						font-weight: $font-weight-medium;

						@include mq(tablet) {
							font-size: $font-size-med-large;
						}
					}
				}

				.btn {
					font-size: $font-size-small-med;
					font-weight: $font-weight-bold;
					margin-top: 60px;
					transition: all .3s ease;

					&:hover {
						background: $color-white;
						border-color: $color-white;
						color: $color-primary;
					}

					@include mq(mobile) {
						margin: 50px auto 0;
						max-width: 120px;
					}
				}
			}

			.sub-image {
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
				border-radius: 0 $common-border-radius $common-border-radius 0;
				max-width: 590px;
				width: 100%;

				@include mq(tablet) {
					flex: 1;
					max-width: none;
				}


				@include mq(mobile) {
					border-radius: $common-border-radius $common-border-radius 0 0;
					height: 230px;
					order: 0;
					max-width: none;
				}
			}
		}
	}

	.section-projects {
		.sub-content {
			@extend %container;

			h2 {
				text-align: center;
			}

			.sub-intro-copy {
				@extend %container-small;

				text-align: center;
			}

			ul.list-projects {
				@extend %list-default;

				display: flex;
				margin-top: 70px;

				@include mq(mobile) {
					flex-direction: column;
					margin-top: 100px;
				}


				& > li {
					border-radius: $common-border-radius;
					box-shadow: 8px 8px 14px -4px rgba(0, 0, 0, 0.09);
					flex: 1;
					margin-right: 30px;
					position: relative;
					transition: all .3s ease;

					@include mq(tablet-down) {
						margin-right: 15px;
					}


					@include mq(mobile) {
						margin-bottom: 25px;
						margin-right: 0;
					}


					&:last-child {
						margin-right: 0;
					}

					&:hover {
						box-shadow: 8px 8px 14px -4px rgba(0, 0, 0, 0.3);
					}

					.sub-link {
						height: 100%;
						position: absolute;
						top: 0;
						width: 100%;
						z-index: 999;

						&:hover {
							+ .sub-image-wrapper .sub-image {
								transform: scale(1.02);
							}

							~ .sub-title .sub-icon svg {
								animation: arrow-anim-right .5s;
								opacity: 1;
							}
						}
					}

					.sub-title {
						align-items: center;
						display: flex;
						margin: 25px 0 15px;
						overflow: hidden;
						padding: 0 25px;

						@include mq(mobile) {
							font-size: $font-size-h4;
							line-height: $line-height-med;
						}


						h3 {
							font-size: $font-size-larger;
							margin-bottom: 0px;

							@include mq(mobile) {
								padding-right: 50px;
							}
						}

						.sub-icon {
							overflow: hidden;
							margin-left: auto;
							max-width: 31px;
							width: 100%;

							@include mq(mobile) {
								margin-right: 10px;
							}


							svg {
								opacity: 0;
								height: 15px;
								margin: 0;
								transition: opacity .3s ease;
								width: 30px;

								@include mq(mobile) {
									opacity: 1;
								}
							}
						}
					}

					.sub-copy {
						padding: 0 25px 40px;

						p {
							font-size: $font-size-standard;
							font-weight: $font-weight-regular;

							@include mq(mobile) {
								line-height: $line-height-large;
							}
						}
					}

					.sub-image-wrapper {
						border-radius: $common-border-radius $common-border-radius 0 0;
						overflow: hidden;

						.sub-image {
							background-color: $color-neutral;
							background-position: center center;
							background-size: cover;
							height: 222px;
							transition: all .3s ease;

							@include mq(tablet-down) {
								height: 180px;
							}


							@include mq(mobile) {
								height: 160px;
							}
						}
					}
				}
			}
		}

		.sub-learn-more {
			margin-top: 70px;
			text-align: center;

			@include mq(mobile) {
				margin-top: 50px;
			}


			& > p {
				font-size: $font-size-h4;
			}

			.btn {
				margin: 30px auto 0;
				max-width: 225px;

				@include mq(mobile) {
					font-size: $font-size-tiny;
				}
			}
		}
	}
}
