#programmes {
	.section-hero {
		@extend %common-hero-large;
	}

	.section-main-copy {
		padding: 80px 0;

		@include mq(mobile) {
			padding: 40px 0;
		}


		.sub-content {
			@extend %container;

			display: flex;

			@include mq(mobile) {
				flex-direction: column;
			}


			& > .sub-copy {
				flex: 1;
				padding-right: 125px;

				@include mq(tablet-down) {
					padding-right: 50px;
				}


				@include mq(mobile) {
					padding-right: 0;
					text-align: center;
				}


				h1, h2, h3, h4, h5 {
					font-size: $font-size-h4;
				}
			}

			.sub-staff-testimonials {
				background: $color-neutral-dark;
				border-radius: 0 0 $common-border-radius $common-border-radius;
				height: 100%;
				max-width: 555px;
				position: relative;
				width: 100%;

				@include mq(tablet-down) {
					max-width: 360px;
				}


				@include mq(mobile) {
					margin-top: 60px;
					max-width: none;
				}


				ul.list-testimonials {
					margin: 0;

					li {
						margin: 0;

						.sub-asset {
							background-color: $color-neutral;
							height: 270px;
							position: relative;

							@include mq(mobile) {
								height: 155px;
							}


							iframe {
								height: 100%;
								width: 100%;
							}

							.sub-image {
								background-position: center center;
								background-size: cover;
								height: 100%;
								left: 0;
								position: absolute;
								top: 0;
								width: 100%;
							}
						}

						.sub-main-copy {
							padding: 20px 40px 50px 40px;

							@include mq(mobile) {
								padding: 65px 30px 40px;
							}


							.sub-caption {
								color: $color-white;
							}

							.sub-copy {
								margin: 60px 0 30px;

								@include mq(mobile) {
									margin: 20px 0;
								}


								p {
									color: $color-white;
									font-size: $font-size-h4;
									font-weight: $font-weight-semibold;

									@include mq(tablet-down) {
										font-size: $font-size-large;
									}


									@include mq(mobile) {
										font-size: $font-size-med;
									}
								}
							}

							.sub-name {
								color: $color-white;
								font-weight: $font-weight-bold;
								margin-bottom: -5px;
							}

							.sub-company {
								color: $color-white;
							}
						}
					}
				}

				ul.slick-dots {
					background: $color-neutral-dark;
					height: 20px;
					padding-left: 20px;
					position: absolute;
					right: 0;
					top: 295px;

					@include mq(mobile) {
						background: none;
						left: 10px;
						top: 180px;
					}


					& > li {
						outline: none;
						margin-right: 15px;

						button {
							height: 11px;
							outline: none;
							width: 11px;
						}
					}
				}
			}
		}
	}

	.section-sub-projects {
		.sub-content {
			@extend %container;

			h2 {
				margin-bottom: 80px;
				text-align: center;

				@include mq(mobile) {
					font-size: $font-size-h4;
					margin-bottom: 60px;
				}
			}

			ul.list-sub-projects {
				@extend %list-default;

				display: flex;
				flex-wrap: wrap;

				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					display: block;
				}

				@include mq(mobile) {
					flex-direction: column;
				}


				& > li {
					box-shadow: 8px 8px 14px -4px rgba(0, 0, 0, 0.09);
					flex: 0 0 calc(50% - 15px);
					margin-bottom: 45px;
					margin-right: 30px;

					@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
						display: inline-block;
						flex: none;
						width: calc(50% - 19px);
					}

					@include mq(mobile) {
						flex: 1;
						margin-right: 0;
						width: 100%;
					}


					&:nth-child(2n+2) {
						margin-right: 0;
					}

					.sub-image {
						background-position: center center;
						background-repeat: no-repeat;
						background-size: cover;
						border-radius: $common-border-radius $common-border-radius 0 0;
						height: 200px;
					}

					.sub-copy-wrapper {
						padding: 35px 25px 50px;
						position: relative;

						@include mq(mobile) {
							flex-direction: column;
						}


						.sub-title {
							@extend %line-under-heading;

							font-size: $font-size-larger;
							margin-bottom: 45px;
							padding-right: 50px;

							@include mq(mobile) {
								order: 1;

								&:before {
									left: 15px;
								}
							}
						}

						.sub-logo {
							position: absolute;
							right: 20px;
							top: 30px;

							img {
								display: block;
								max-height: 60px;
								max-width: 140px;
								width: auto;
							}

							@include mq(mobile) {
								display: none;
							}


							&.mod-mobile {
								display: none;

								@include mq(mobile) {
									display: block;
									margin-bottom: 20px;
									position: relative;
									right: auto;
									top: auto;
								}
							}
						}

						.sub-intro-info {
							@extend %list-default;

							display: flex;
							margin-bottom: 10px;

							& > li {
								&:first-child {
									margin-right: 10px;

									@include mq(mobile) {
										margin-right: 0;
									}
								}

								strong {
									color: $color-text-light;
									font-weight: $font-weight-regular;
								}
							}
						}

						.sub-intro-copy {
							margin-bottom: 25px;

							p {
								color: $color-text;
							}
						}

						.sub-copy {
							margin-bottom: 25px;

							h3 {
								font-size: $font-size-small-med;
								margin-bottom: 5px;
							}
						}

						.sub-achievements {
							margin-top: 30px;

							h3 {
								font-size: $font-size-standard;
								margin-bottom: 0;
							}
						}
					}

					.sub-read-more {
						cursor: pointer;
					}
				}
			}
		}
	}
}
